.travel-home {
  display: grid; /* Using grid instead of flex */
  justify-content: center; /* Center the entire grid on the page */
  gap: 20px; 
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr)); 
}

.location-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.location-item img {
  max-width: 170px;
  height: auto;
  margin-bottom: 10px;
}


